<template>
  <div class="text-sm" :class="`text-${color}`">
    <nuxt-link class="mr-2 text-sm font-semibold" to="/" aria-label="Home">
      <icon name="pt-home-alt" role="presentation" />
    </nuxt-link>
    <template v-if="section">
      <span v-text="'/'" />
      <nuxt-link class="mx-2 font-semibold" :to="setPath">{{ section }}</nuxt-link>
    </template>
    <template v-if="setData?.label && showCategory">
      <span class="mr-2" v-text="'/'" />
      <component
        :is="onRedirect ? NuxtLink : 'span'"
        :to="data.slug"
        :class="['font-semibold', setColor]"
      >
        {{ setData.label }}
      </component>
    </template>
  </div>
</template>

<script>
import { COMMUNITY_BASE_URL, STARTUPS_BASE_UR } from '~/utils/enums';
export default {
  name: 'PtBreadcrumbs',
  props: {
    path: {
      type: String,
      default: ''
    },
    data: {
      type: [Object, String],
      default: () => ({})
    },
    section: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'text-gray-dark'
    },
    showCategory: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { createBreadcrumbsSeo } = useHeadSeo();
    watch(
      () => [props.data, props.section, props.path],
      val => createBreadcrumbsSeo(val[0], val[1], val[2]),
      { immediate: true, deep: true }
    );

    return {
      NuxtLink: resolveComponent('NuxtLink')
    };
  },
  computed: {
    setPath() {
      return this.path ? (this.path.includes('/') ? this.path : `/${this.path}`) : this.$route.path;
    },
    setColor() {
      return this.color.includes('text-') ? this.color : `text-${this.color}`;
    },
    setData() {
      if (this.data instanceof Object ? Object.keys(this.data).length : this.data) {
        if (this.data instanceof Object) {
          return { ...this.data, slug: this.data.slug ?? '', label: this.data.label ?? '' };
        } else return this.data;
      } else return null;
    },
    onRedirect() {
      return [COMMUNITY_BASE_URL, STARTUPS_BASE_UR].some(val => this.$route.path.includes(val));
    }
  }
};
</script>
