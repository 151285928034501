export const useComments = () => {
  const sso = ref({ hash: '', userData: '' });
  const store = useStore();
  const getAuth = computed(() => store.getters['auth/getByField']('loggedIn'));

  const identityHyvorData = async () => {
    if (store.getters['auth/getByField']('loggedIn')) {
      await store.dispatch('tools/identityHyvorData').then(({ data }) => {
        const { sso: ssoData } = data;
        sso.value = ssoData;
      });
    }
  };

  watch(getAuth, () => {
    (async () => await identityHyvorData())();
  });

  return {
    sso,
    identityHyvorData
  };
};
