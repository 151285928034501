<template>
  <Comments
    :key="sso.hash"
    :website-id="environmentId"
    :page-id="id"
    :page-language="pageLanguage"
    :page-url="pageUrl"
    :page-title="pageTitle"
    :loading="loading"
    :sso-user="sso.userData"
    :sso-hash="sso.hash"
    @loaded="onLoaded"
    @auth:login:clicked="$msal.signIn($route.path)"
  />
</template>

<script>
import * as HyvorTalk from '@hyvor/hyvor-talk-vue';

export default {
  name: 'PtCommentEmbed',
  components: { Comments: HyvorTalk.Comments },
  props: {
    // Your Hyvor Talk Website ID
    websiteId: {
      type: String,
      default: ''
    },
    // The absolute URL of the page. If kept false, we will use the canonical URL or location.href.
    url: {
      type: String,
      default: ''
    },
    // An identifier for the current page. We recommend using the 'slug'
    id: {
      type: String,
      default: ''
    },
    // Title of the page. document.title is used by default.
    title: {
      type: String,
      default: ''
    },
    // Configures when to load the iframe. Scroll down to using loading modes for more details.
    loading: {
      type: String,
      default: 'default',
      validator: val => val.match(/(default|lazy|manual)/)
    },
    // Set language for each page
    language: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { sso } = useComments();
    const config = useRuntimeConfig();
    const { locale } = useI18n();
    const environmentId = computed(
      () => props.websiteId || config.public.APP_HYVOR_TALK_WEBSITE_ID
    );
    const pageLanguage = computed(() => props.language || locale.value);
    const pageUrl = computed(() => props.url || location.href);
    const pageTitle = computed(() => props.title || document.title);
    const onLoaded = () => {};
    return {
      onLoaded,
      sso,
      environmentId,
      pageLanguage,
      pageUrl,
      pageTitle
    };
  }
};
</script>
